<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm8>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            <span>
              <b> Subjects > {{ grade.name }} </b>
              <div
                style="font-weight: normal; font-size: 13px; padding-left: 30px"
                v-if="grade.id"
              ></div>
            </span>
            <v-spacer></v-spacer>
            <v-scroll-x-transition>
              <add-button
                v-if="grade.id"
                permission="subject-create"
                @action="
                  (form.dialog = true), $refs.form ? $refs.form.reset() : ''
                "
                >New Subject
              </add-button>
            </v-scroll-x-transition>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-left">
                  <strong
                    >{{ item.name
                    }}{{
                      item.final_fullmark ? "(" + item.final_fullmark + ")" : ""
                    }}
                    <small style="color: #999" v-if="item.sub_type === 'opt'"
                      >(optional)</small
                    >
                  </strong>
                  <br />
                  <small
                    style="color: #666"
                    v-for="(teacher, ri) in item.teachers"
                    :key="ri"
                  >
                    <span v-if="ri <= 6"
                      >{{ teacher.name
                      }}<span v-if="ri < item.teachers.length - 1"
                        >,
                      </span></span
                    >
                  </small>
                </td>
                <td class="text-center">{{ item.code }}</td>
                <td class="text-center">
                  {{ item.credit_hour }} + {{ item.credit_hour_pr }}
                </td>
                <td class="text-center" style="padding: 0">
                  <v-icon color="success" small v-if="item.exam"
                    >check_circle</v-icon
                  >
                  <v-icon color="error" small v-else>close</v-icon>
                </td>
                <td class="text-right">
                  <edit-button
                    permission="subject-update"
                    @agree="form.edit(item)"
                  />
                  <delete-button
                    permission="subject-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-card-actions>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 sm4>
        <v-card outlined class="grade-select primary--border">
          <v-card-title class="title">
            Select Grade
            <v-spacer />
            <v-icon>filter_list</v-icon>
          </v-card-title>
          <v-card-text style="text-align: center">
            <v-chip
              class="ma-1"
              v-for="(g, k) in grades"
              :key="k"
              :color="g.id === grade.id ? 'primary' : ''"
              @click="grade = g"
              >{{ g.name }}
            </v-chip>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              @submit.prevent="save"
              v-model="valid"
              :lazy-validation="lazy"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
            >
              <v-container pa-0 grid-list-md>
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-text-field
                      outlined
                      dense
                      autocomplete="off"
                      label="Subject Name*"
                      required
                      class="pa-0"
                      v-model="form.name"
                      name="name"
                      :error-messages="form.errors.get('name')"
                      :rules="[(v) => !!v || 'Name is required']"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      outlined
                      dense
                      autocomplete="off"
                      label="Subject Code*"
                      required
                      class="pa-0"
                      v-model="form.code"
                      name="code"
                      :error-messages="form.errors.get('code')"
                      maxlength="10"
                      :rules="[(v) => !!v || 'Code is required']"
                    />
                      <!-- v-mask="'####'" -->
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      type="number"
                      outlined
                      dense
                      autocomplete="off"
                      label="Theory Full Mark*"
                      required
                      class="pa-0"
                      v-model="form.th_final_full_mark"
                      name="th_final_full_mark"
                      :error-messages="form.errors.get('th_final_full_mark')"
                      :rules="[(v) => !!v || 'Theory Mark is required']"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      type="number"
                      outlined
                      dense
                      autocomplete="off"
                      label="Practical Full Mark*"
                      required
                      class="pa-0"
                      v-model="form.pr_final_full_mark"
                      name="final_pr_fullmark"
                      :error-messages="form.errors.get('final_pr_fullmark')"
                      :rules="[(v) => !!v || 'Practical Mark is required']"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      disabled
                      type="number"
                      outlined
                      dense
                      autocomplete="off"
                      label="Final Full Mark*"
                      required
                      class="pa-0"
                      v-model="form.final_fullmark"
                      name="final_fullmark"
                      :error-messages="form.errors.get('final_fullmark')"
                      maxlength="6"
                      :rules="[(v) => !!v || 'Final Full Mark is required']"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      @change="checkCredit"
                      autocomplete="off"
                      label="Credit Hour (TH)*"
                      required
                      class="pa-0"
                      v-model="form.credit_hour"
                      name="code"
                      :error-messages="form.errors.get('credit_hour')"
                      :rules="[(v) => !!v || 'Credit Hour is required']"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      @change="checkCredit"
                      autocomplete="off"
                      label="Credit Hour (PR)*"
                      required
                      class="pa-0"
                      v-model="form.credit_hour_pr"
                      name="code"
                      :error-messages="form.errors.get('credit_hour')"
                      :rules="[(v) => !!v || 'Credit Hour is required']"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      outlined
                      dense
                      autocomplete="off"
                      label="Abbreviation*"
                      hint="short code for the subject eg: En"
                      required
                      class="pa-0"
                      v-model="form.sub_code"
                      name="code"
                      :error-messages="form.errors.get('sub_code')"
                      maxlength="6"
                      :rules="[(v) => !!v || 'Short Code is required']"
                    />
                  </v-flex>
                  <v-flex xs8>
                    <v-select
                      outlined
                      dense
                      :items="types"
                      required
                      class="pa-0"
                      label="Subject Type*"
                      v-model="form.sub_type"
                      :error-messages="form.errors.get('sub_type')"
                      :rules="[(v) => !!v || 'Subject Type is required']"
                    />
                  </v-flex>
                  <v-flex xs8>
                    <v-checkbox color="blue" v-model="form.exam">
                      <template v-slot:label>
                        <div style="color: #333">
                          Subject will be
                          <strong>{{
                            form.exam === true ? "included" : "excluded"
                          }}</strong>
                          in exam.
                          <p style="line-height: 10px">
                            <small style="color: #666; font-size: 10px"
                              >Uncheck this if this subject is not part of the
                              terminal examination.
                            </small>
                          </p>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-flex>
                  <v-flex xs4 pt-4 v-if="form.sub_type == 'opt'">
                    <div style="color: #333">
                      <a
                        @click.prevent="
                          () => {
                            studentSelection = true;
                          }
                        "
                        href=""
                        >Optional Group</a
                      >
                      <p style="line-height: 10px">
                        <small style="color: #666; font-size: 10px">
                          <strong>{{ form.name }}</strong> Group
                        </small>
                      </p>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
              <span v-if="form.errors.message"
                >{{ form.errors.message }}<br
              /></span>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="
                (form.dialog = false),
                  form.reset(),
                  $refs.form ? $refs.form.reset() : ''
              "
              >Close</v-btn
            >
            <v-btn color="success" text @click="validate">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    form: new Form(
      {
        name: "",
        code: "",
        final_fullmark: "",
        sub_type: "comp",
        sub_code: "",
        exam: true,
        batch_id: "",
        grade_id: "",
        credit_hour: "",
        credit_hour_pr: "",
        is_charged: "0",
        th_final_full_mark: "",
        pr_final_full_mark: "",
      },
      "/api/subject"
    ),
    search: null,
    pagination: {
      rowsPerPage: 20,
      sortBy: "code",
    },
    studentSelection: false,
    headers: [
      { text: "#", align: "center", value: "id", width: "5%", sortable: false },
      {
        text: "Name",
        align: "left",
        value: "name",
        width: "35%",
        sortable: false,
      },
      {
        text: "Code",
        align: "left",
        value: "code",
        width: "5%",
        sortable: false,
      },
      {
        text: "Credit Hours",
        align: "center",
        value: "credit_hour",
        width: "15%",
        sortable: false,
      },
      {
        text: "Exam",
        align: "center",
        value: "code",
        width: "5%",
        sortable: false,
      },
      { text: "Action", align: "right", sortable: false, width: "35%" },
    ],
    grades: [],
    grade: {},
    types: [
      {
        text: "Compulsory",
        value: "comp",
      },
      {
        text: "Optional",
        value: "opt",
      },
    ],
    chartData: [],
    chartOptions: {
      title: {
        text: "Subject-Wise Students",
        align: "center",
      },
      legend: {
        show: false,
        floating: true,
      },
      labels: [],
    },
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function () {
      this.get();
    },
    "form.name": {
      handler(name) {
        this.form.name = this.capitalizeString(name);
      },
    },
    "form.dialog": function (value) {
      if (value) {
        this.form.batch_id = this.batch.id;
        this.form.grade_id = this.$route.params.gradeId;
      }
    },
    grade: function () {
      this.get();
    },
    batch: function (value) {
      this.getGrades();
    },
    "form.th_final_full_mark" : function(value) {
      if(value) {
        this.calculateFullmarks()
      }
    },
    "form.pr_final_full_mark" : function(value) {
      if(value) {
        this.calculateFullmarks()
      }
    },
  },
  created() {
    this.getGrades();
  },
 

  mounted() {
    this.form.batch_id = this.batch.id;
  },

  methods: {
    calculateFullmarks(){
      if (parseFloat(this.form.pr_final_full_mark) > 100) this.form.pr_final_full_mark = '';
      if (parseFloat(this.form.th_final_full_mark) > 100) this.form.th_final_full_mark = '';

      if((parseFloat(this.form.th_final_full_mark) + parseFloat(this.form.pr_final_full_mark)) > 100) {
        this.form.pr_final_full_mark = '';
        this.form.th_final_full_mark = '';
        this.form.final_fullmark = '';
      }
      this.form.final_fullmark = (
        (this.form.th_final_full_mark === '' ? 0 : parseFloat(this.form.th_final_full_mark))
        +
        (this.form.pr_final_full_mark === '' ? 0 : parseFloat(this.form.pr_final_full_mark))
      );
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    checkCredit() {
      if (this.form.credit_hour > 8) {
        this.form.credit_hour = 8;
      }
    },
    checkCreditPR() {
      if (this.form.credit_hour_pr > 5) {
        this.form.credit_hour_pr = 5;
      }
    },

    get(params) {
      let extraParams = "batch=" + this.batch.id + "&grade=" + this.grade.id;

      if (
        [null, undefined].includes(this.batch.id) ||
        [null, undefined].includes(this.grade.id)
      )
        return;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form
        .get(null, query)
        .then(({ data }) => {
          this.pagination.totalItems = data.meta.total;
        })
        .finally(() => {
          this.setChart();
        });
    },
    save() {
      this.form.grade_id = this.grade.id;
      this.form.batch_id = this.batch.id;
      this.form.exam = this.form.exam === true ? 1 : 0;
      this.form.store().then(() => {
        this.setChart();
      });
    },

    getGrades() {
      this.form.items.data = [];
      this.grade = {};
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { id: item.id, name: item.name };
          });
          if (data.data.length > 0) {
            this.grade.id = data.data[0].id;
            this.grade.name = data.data[0].name;
            this.get();
          }
        });
    },

    /*selectGrade(grade) {
                this.grade = grade;
                this.get()
            },*/

    setChart(data) {
      if (data == null) {
        data = this.form.items.data;
      }

      let labels = data.map((item) => {
        return item.name;
      });

      this.chartOptions.labels = labels;

      this.chartData = data.map((item) => {
        return Math.floor(Math.random() * Math.floor(50));
      });
    },
    capitalizeString(string) {
      if (string === undefined || string === null) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.grade-select {
  .theme--light.v-chip {
    border-radius: 2px;

    &.primary {
      background-color: #0064c7;
      color: white;
    }
  }
}
</style>
